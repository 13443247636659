



















import { Component, Vue } from 'vue-property-decorator';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import BoxPage from '@/themes/v1/components/BoxPage.vue';
import { SsrCtx, WithAsyncData } from '@/core/vue.types';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { ChatService } from '@/modules/sts/chat/chat.service';
import { getModule } from 'vuex-module-decorators';
import { TicketsStore } from '@/themes/v1/stores/tickets.store';
import { RateMessagePageStatus } from '@/modules/sts/chat/rate-message-status';

@Component({
  components: { BoxContent, BoxPage },
})
export default class MessageRatePage extends Vue implements WithAsyncData {
  public status!: RateMessagePageStatus;

  async asyncData(ctx: SsrCtx): Promise<any> {
    const ticketsStore = getModule(TicketsStore, ctx.store);
    if (ticketsStore.rateMessagePageValue !== RateMessagePageStatus.Undefined) {
      return {
        status: ticketsStore.rateMessagePageValue,
      };
    }
    const params = ctx.route.params;
    const ticketNumber = params['ticket'];
    const messageId = params['messageId'];
    const isHelpful = (params['helpful'] || '').toLocaleLowerCase() == 'true';

    const chatService = resolveFromCtx<ChatService>('chatService', ctx);
    try {
      await chatService.rateMessage(ticketNumber, messageId, isHelpful);
      const status = isHelpful
        ? RateMessagePageStatus.RateUseful
        : RateMessagePageStatus.RateUseless;
      ticketsStore.setRateMessagePageValue(status);
      return {
        status,
      };
    } catch (e) {
      return {
        status: RateMessagePageStatus.Error,
      };
    }
  }
}
